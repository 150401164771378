import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import ReactMarkdown from 'react-markdown'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'

export const query = graphql`
    query getArticle($id: String) {
        background1: file(relativePath: { eq: "arena-news/bg-01.jpg" }) {
            childImageSharp {
                gatsbyImageData(width: 1000, formats: [AUTO, WEBP])
            }
        }
        background2: file(relativePath: { eq: "arena-news/bg-02.jpg" }) {
            childImageSharp {
                gatsbyImageData(width: 1000, formats: [AUTO, WEBP])
            }
        }
        background3: file(relativePath: { eq: "arena-news/bg-03.jpg" }) {
            childImageSharp {
                gatsbyImageData(width: 1000, formats: [AUTO, WEBP])
            }
        }

        ad1: file(relativePath: { eq: "arena-news/ad-01.png" }) {
            childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP])
            }
        }
        ad2: file(relativePath: { eq: "arena-news/ad-02.png" }) {
            childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP])
            }
        }
        ad3: file(relativePath: { eq: "arena-news/ad-03.png" }) {
            childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP])
            }
        }

        footer: file(relativePath: { eq: "arena-news/footer.png" }) {
            childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP])
            }
        }

        article: datoCmsArenaNews(id: { eq: $id }) {
            id
            meta {
                publishedAt
            }
            # seo {
            #     description
            #     title
            #     twitterCard
            #     image {
            #         url(imgixParams: {w: "1200", h: "630", fit: "crop"})
            #     }
            # }
            title
            slug
            body
            featuredImage {
                gatsbyImageData(
                    imgixParams: { w: "1000", h: "250", fit: "crop" }
                )
                fluid(imgixParams: { w: "1000", h: "250", fit: "crop" }) {
                    aspectRatio
                    base64
                    height
                    sizes
                    src
                    srcSet
                    width
                }
            }
            footerImage {
                gatsbyImageData(
                    imgixParams: { w: "525", h: "300", fit: "crop" }
                )
                fluid(imgixParams: { w: "525", h: "300", fit: "crop" }) {
                    aspectRatio
                    base64
                    height
                    sizes
                    src
                    srcSet
                    width
                }
            }
            footerText
        }
    }
`

const ArenaNewsTemplate = ({
    data,
    pageContext: { globalConfig, locale },
    ...props
}) => {
    const article = {
        title: data?.article?.title,
        slug: data?.article?.slug,
        body: data?.article?.body,
        category: data?.article?.category?.categoryName,
        featuredImage: data?.article?.featuredImage,
        footerImage: data?.article?.footerImage,
        footerText: data?.article?.footerText,
        publishedAt: data?.article?.meta?.publishedAt,
    }

    const backgrounds = [
        data?.background1,
        data?.background2,
        data?.background3,
    ]
    const ads = [getImage(data?.ad1), getImage(data?.ad2), getImage(data?.ad3)]
    const [adVersion, setAdVersion] = useState(null)

    useEffect(() => {
        setAdVersion(Math.floor(Math.random() * 3))
    }, [])

    return (
        <>
            <div className="relative bg-blackblue text-left">
                <GatsbyImage
                    image={getImage(backgrounds[Math.floor(Math.random() * 3)])}
                    className="w-full h-full absolute top-0 left-0 z-0"
                    alt="Star Wars: Hunters"
                    style={{ position: 'absolute' }}
                />
                <span
                    className="block w-full absolute top-0 left-0 bg-gradient-to-b from-blackblue to-transparent"
                    style={{ height: '20%' }}
                />

                <div className="relative z-10">
                    <Image
                        fluid={article?.featuredImage?.fluid}
                        className="w-full h-auto align-top"
                    />

                    <div className="max-w-6xl mx-auto py-12 md:py-20 md:pt-12 px-8 lg:px-20 xl:px-32 z-10 relative text-white text-lg leading-9">
                        <h1
                            className="outline-heading max-w-xl m-0 text-4xl md:text-5xl lg:text-6xl font-display tracking-wide font-bold text-white uppercase italic z-20"
                            data-text={article.title}
                        >
                            <span className="outline-heading__inner">
                                {article?.title}
                            </span>
                        </h1>

                        <div
                            className="mt-8 md:mt-12 wysiwyg"
                            dangerouslySetInnerHTML={{ __html: article.body }}
                        />

                        {article?.footerImage?.fluid ? (
                            <Image
                                fluid={article?.footerImage?.fluid}
                                className={`w-full h-auto mt-12 md:mt-16 clipped-image ${
                                    Math.round(Math.random()) > 0 ? 'v1' : 'v2'
                                } rounded-xl`}
                            />
                        ) : null}

                        <div class="mt-8 md:mt-12">
                            <div class="flex flex-col items-end">
                                <div class="text-sm text-white">AD</div>

                                <div className="mt-1.5 overflow-hidden rounded-xl">
                                    <GatsbyImage
                                        image={ads[adVersion]}
                                        className={`w-full h-auto relative`}
                                        alt="Star Wars: Hunters"
                                    />
                                </div>
                            </div>
                        </div>

                        {article?.footerText ? (
                            <ReactMarkdown className="mt-12 md:mt-16 wysiwyg">
                                {article?.footerText}
                            </ReactMarkdown>
                        ) : null}

                        <div className="text-center">
                            <GatsbyImage
                                image={getImage(data.footer)}
                                className="inline-block w-full max-w-md h-auto mt-12 md:mt-16 mx-auto"
                                alt="Star Wars: Hunters Arena News"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ArenaNewsTemplate
